import {
  Persona,
  PersonaCreateFields,
  PersonaGetAllFields,
  PersonaSearchResponse,
  PersonaUpdateFields,
} from '../types/Persona';
import BaseAPI from './BaseAPI';

export default class PersonaAPI extends BaseAPI {
  async findPersonas({
    organizationId,
    persona_name = '',
    pageSize = 10,
    page = 1,
  }: PersonaGetAllFields): Promise<PersonaSearchResponse> {
    const { data } = (await this.httpClient.get(`${this.host.tcUrl}/v1/user-service/Personas`, {
      headers: {
        'tt-x-organization-key': organizationId,
      },
      query: {
        page,
        pageSize,
        persona_name,
      },
    })) as unknown as { data: PersonaSearchResponse };

    return data;
  }

  async findTitles(organizationId: string) {
    const endpoint = `${this.host.tcUrl}/v1/user-service/Titles/GetOrgTitles`;
    const res = await this.httpClient.get(endpoint, {
      headers: { 'TT-X-Organization-Key': organizationId },
    });
    return res.data;
  }

  async createPersona(payload: PersonaCreateFields, orgId: string): Promise<Persona> {
    const endpoint = `${this.host.tcUrl}/v1/user-service/Personas`;
    const newPerms = Object.keys(payload?.permissions || {});

    try {
      const res = (await this.httpClient.post(endpoint, {
        data: {
          ...payload,
          permissions: newPerms,
        },
        headers: {
          'TT-X-Organization-Key': orgId,
        },
      })) as unknown as Promise<Persona>;
      return res;
    } catch (e) {
      console.error('API - Failed to create persona: ', JSON.stringify(e));
      throw e;
    }
  }

  async updatePersona(personaId: string, payload: PersonaUpdateFields) {
    // const endpoint = `${this.host.tcUrl}/user-service/Personas/{personaId}`;
    // const res = await this.httpClient.patch(endpoint);
    return {
      id: personaId,
      titles: payload.titles,
      permissions: payload.permissions,
      organizationId: '12329dc6-cfc9-4136-befb-c108b3b48123',
      name: payload.name,
      description: 'This is a test Persona',
      parentPersonaId: payload.parentPersonaId,
    };
  }
}
