// @ts-nocheck
import _ from 'lodash';
import { ConversationAnchorPoints } from '../models/enums';
import BaseAPI, { ROUTES, VERSIONS } from './BaseAPI';

const { VERSION_TWO, VERSION_FIVE } = VERSIONS;
const ROSTER_ENTITY_TYPES = ['user', 'distributionList'];
const { CONVERSATION_FIND_ALL } = ROUTES;

export default class RosterAPI extends BaseAPI {
  async findAll(options = { organizationId: null }) {
    const { version = VERSION_TWO } = super.getVersion(CONVERSATION_FIND_ALL);
    let orgId;
    const query = { render_metadata: 'true', latest_message: this.config.lazyLoading || undefined };

    if (version === VERSION_FIVE) {
      orgId = options.organizationId;
      query.mark_as_delivered = 'true';
      query.paginated = 'true';
      query.organization_id = orgId || undefined;
    }

    const res = await this.httpClient.get('/:version/roster', {
      query,
      urlParams: { version },
      headers: {
        'tt-x-features': 'vwr',
      },
    });

    if (res.data.continuation) {
      const items = await this._findAllPaginated(res.data.continuation, orgId, res.data.items);
      return items;
    }

    return res.data.items || res.data;
  }

  async _findAllPaginated(continuation, orgId, items) {
    if (continuation === null) {
      return items;
    }

    const res = await this.httpClient.get('/v5/roster', {
      query: {
        render_metadata: 'true',
        mark_as_delivered: 'true',
        paginated: 'true',
        continuation: encodeURI(continuation),
        organization_id: orgId || undefined,
      },
      headers: {
        'tt-x-features': 'vwr',
      },
    });

    items = items.concat(res.data.items);

    return this._findAllPaginated(res.data.continuation, orgId, items);
  }

  async add(counterPartyType: string, counterPartyId: string, organizationId: string) {
    if (!_.includes(ROSTER_ENTITY_TYPES, counterPartyType)) return;

    const headers = {};

    if (organizationId) {
      headers['TT-X-Organization-Key'] = organizationId;
    }

    const xmlnsType = this.__typeToNS(counterPartyType);

    await this.httpClient.post('/v2/roster', {
      headers,
      data: {
        entity: counterPartyId,
        type: xmlnsType,
      },
    });
  }

  async remove(counterPartyType: string, counterPartyId: string, organizationId: string) {
    if (!_.includes(ROSTER_ENTITY_TYPES, counterPartyType)) return;

    const headers = {};
    const xmlnsType = this.__typeToNS(counterPartyType);

    if (organizationId) {
      headers['TT-X-Organization-Key'] = organizationId;
      headers['TT-X-Type'] = xmlnsType;
    }

    await this.httpClient.del('/v2/roster/:counterPartyId', {
      headers,
      urlParams: { counterPartyId },
      data: {
        type: xmlnsType,
      },
    });
  }

  async getTimeline(
    rosterId: string,
    entityType: 'user' | 'group' | 'distributionList',
    organizationId: string,
    {
      anchorPoint,
      size = 50,
      direction,
      markAsDelivered = true,
      continuation,
    }: {
      anchorPoint: string;
      size: number;
      direction: 'higher' | 'lower';
      markAsDelivered: boolean;
      continuation?: number;
    }
  ) {
    const headers = {};

    const xmlnsType = this.__typeToNS(entityType);
    headers['TT-X-Type'] = xmlnsType;

    if (organizationId) {
      headers['TT-X-Organization-Key'] = organizationId;
    }
    if (
      this.config.lazyLoading &&
      ConversationAnchorPoints.fromServer(anchorPoint) === ConversationAnchorPoints.CONVERSATION_END
    ) {
      anchorPoint = ConversationAnchorPoints.CONTINUATION;
    }

    const res = await this.httpClient.get('/v2/roster/:rosterId/timeline', {
      headers,
      urlParams: { rosterId },
      query: {
        continuation: continuation,
        anchor_point: anchorPoint,
        size: size,
        direction: direction,
        mark_as_delivered: markAsDelivered,
      },
    });

    return res.data;
  }

  __typeToNS(counterPartyType) {
    return COUNTER_PARTY_TYPE_TO_NS[counterPartyType];
  }
}

const COUNTER_PARTY_TYPE_TO_NS = {
  user: 'tigertext:entity:account',
  group: 'tigertext:entity:group',
  distributionList: 'tigertext:entity:list',
};
