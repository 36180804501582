import React, { useCallback } from 'react';

import { OrganizationSetting, OrganizationSettings } from '../types';
import { ALL_OFF_INDV, TTL_CONTROL_OPTIONS } from '../utils/commonControlOptions';
import { Checkbox } from './Checkbox/Checkbox';
import { Dropdown, DropdownOption } from './Dropdown/Dropdown';
import { Input } from './Input/Input';
import { Number } from './Number/Number';
import { RadioInput } from './RadioInput/RadioInput';
import { Slider, SliderOption } from './Slider/Slider';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

type RadioOption = {
  label: string;
  value: OrganizationSetting[keyof OrganizationSetting];
};

type ReduxProps = {
  currentValue: unknown;
  handleChange: (updatedSetting: OrganizationSetting) => void;
};

type StandardInputProps = {
  disabled?: boolean;
  options?: DropdownOption[] | SliderOption[] | RadioOption[];
  settingName: keyof OrganizationSettings;
  type: string;
  multiple?: boolean;
};

export const StandardSettingInput = ({
  currentValue,
  disabled,
  handleChange,
  options,
  settingName,
  type,
  multiple = false,
}: StandardInputProps & ReduxProps) => {
  switch (type) {
    case 'checkbox':
      return (
        <Checkbox
          checked={!!currentValue}
          dataTestId={`${settingName} ${type}`}
          onClick={() => handleChange({ [settingName]: !currentValue })}
          disabled={disabled}
          multiple={multiple}
        />
      );
    case 'checkboxBasedOnDropdown':
      return (
        <Checkbox
          checked={currentValue !== 'off'}
          dataTestId={`${settingName} ${type}`}
          onClick={() =>
            handleChange({ [settingName]: currentValue === 'off' ? 'individual' : 'off' })
          }
          disabled={disabled}
          multiple={multiple}
        />
      );
    case 'dropdown':
      const dropdownOptions = (options || ALL_OFF_INDV) as DropdownOption[];
      return (
        <Dropdown
          dataTestId={`${settingName} ${type}`}
          defaultValue={currentValue as string}
          onChange={(val) => {
            handleChange({
              [settingName]: val as OrganizationSettings[typeof settingName],
            });
          }}
          options={dropdownOptions}
          disabled={disabled}
        />
      );
    case 'slider':
      if (currentValue === undefined) return <></>;
      const sliderOptions = (options || TTL_CONTROL_OPTIONS) as SliderOption[];
      const index = sliderOptions.findIndex(({ value }) => currentValue === value) || 0;

      return (
        <Slider
          dataTestId={`${settingName} ${type}`}
          initialIndex={index}
          initialLabel={sliderOptions[index]?.label}
          onChange={(val) => {
            handleChange({
              [settingName]: val as unknown as OrganizationSettings[typeof settingName],
            });
          }}
          options={sliderOptions}
        />
      );
    case 'number':
      return (
        <Number
          dataTestId={`${settingName} ${type}`}
          value={currentValue as string}
          onInputChange={(val: string) => {
            handleChange({
              [settingName]: parseInt(val) as OrganizationSettings[typeof settingName],
            });
          }}
        />
      );
    case 'input':
      return (
        <Input
          dataTestId={`${settingName} ${type}`}
          onInputChange={({ target: { value } }) =>
            handleChange({
              [settingName]: value,
            })
          }
          disabled={disabled}
          value={currentValue as string}
        />
      );
    case 'radio':
      const radioOptions = options as RadioOption[];
      return (
        <div style={{ display: 'flex' }}>
          {radioOptions.map(({ label, value }) => (
            <RadioInput
              dataTestId={`${settingName} ${type} ${label}`}
              key={label}
              name={settingName}
              label={label}
              value={value as string}
              selectedValue={currentValue as string}
              onRadioClick={({ target: { value } }) => {
                let typedValue: string | boolean = value;
                if (typeof currentValue === 'boolean') {
                  typedValue = value === 'true';
                }
                handleChange({
                  [settingName]: typedValue,
                });
              }}
            />
          ))}
        </div>
      );
    default:
      return <></>;
  }
};

export const StandardOrgSettingInput = (props: StandardInputProps) => {
  const currentValue = useAppSelector((state) => state.orgSettings.settings[props.settingName]);
  const dispatch = useAppDispatch();

  const handleChange = useCallback(
    (updatedSetting: OrganizationSetting) => {
      const updatedSettingValue = Object.values(updatedSetting)[0];
      const triggerFn = (triggerValue: boolean | string | number, fn: () => void) =>
        updatedSettingValue === triggerValue
          ? fn()
          : dispatch(actions.updateSettings(updatedSetting));

      const settingTriggers = {
        isPatientAccessCodeEnabled: () =>
          triggerFn(false, () => {
            dispatch(actions.setModal({ name: 'patientAccessCode', data: { updatedSetting } }));
          }),
        isPatientDateOfBirthEnabled: () =>
          triggerFn(false, () => {
            dispatch(actions.setModal({ name: 'patientDOB', data: { updatedSetting } }));
          }),
        isEmojiReactionsEnabled: () =>
          triggerFn(true, () => {
            dispatch(
              actions.setModal({
                name: 'orgSettingEnabled',
                data: {
                  bodyText:
                    'Data on emoji reactions is not available in TigerConnect’s reporting or archiving tools. If you require data and auditing around use of emoji reactions, we recommend not enabling this feature at this time.',
                  headerText: 'Enable Emoji Reactions',
                  updatedSetting,
                },
              })
            );
          }),
        isMentionsEnabled: () =>
          triggerFn(true, () => {
            dispatch(
              actions.setModal({
                name: 'orgSettingEnabled',
                data: {
                  bodyText:
                    'Data on @Mentions is not available in TigerConnect’s reporting or archiving tools. If you require data and auditing around use of @Mentions, we recommend not enabling this feature at this time.',
                  headerText: 'Enable @Mentions',
                  updatedSetting,
                },
              })
            );
          }),
        isConversationExpirationEnabled: () =>
          triggerFn(true, () => {
            dispatch(
              actions.setModal({
                name: 'orgSettingEnabled',
                data: {
                  bodyText:
                    "Enabling this setting will delete all existing empty conversations. Once started, this process can't be stopped, and deleted conversations cannot be restored. Disabling this setting will not stop the cleanup.",
                  headerText: 'Enable Conversation Expiration',
                  updatedSetting,
                },
              })
            );
          }),
      } as Record<string, () => unknown>;

      if (settingTriggers[props.settingName]) {
        settingTriggers[props.settingName]();
      } else {
        dispatch(actions.updateSettings(updatedSetting));
      }
    },
    [dispatch, props.settingName]
  );

  return <StandardSettingInput {...{ ...props, currentValue, handleChange }} />;
};
