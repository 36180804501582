import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useAMContext } from '../../../../contexts/AutomatedMessages';
import { SearchTypes } from '../../../../models/enums/';
import { DeliveryDirections, EventDeliveryMethods } from '../../../../models/enums/DeliveryMethods';
import { MessageTemplateRepositories } from '../../../../models/enums/MessageTemplateRepositories';
import { AttachmentIcon } from '../../';
import { Template, User, WorkflowEvent } from '../../../../types/';
import BEM from '../../../bem';
import { BasicContextMenu, ContextMenu } from '../../ContextMenu/';
import DotsIndicator from '../../DotsIndicator';
import Modal from '../../Modal';
import RecipientSearchPicker from '../../RecipientSearchPicker';
import TemplateContextMenu from '../../ScheduleMessageModal/TemplateContextMenu';
import { detectFormattedVariables } from '../MessageTemplates/TemplateForm';
import { ReactComponent as DropDownChevronSvg } from '../../../images/dropdown-chevron.svg';
import WorkflowEventDeliveryMenu, { DeliveryMethod } from './WorkflowEventDeliveryMenu';
import { useAppSelector } from 'redux-stores';

const { AFTER, BEFORE } = DeliveryDirections;
const { LINK, ONE_WAY_LINK, SMS } = EventDeliveryMethods;
const { DYNAMIC, PERSONAL } = MessageTemplateRepositories;
const NONE_SELECTED_TEMPLATE: Template = {
  id: 'noneSelected',
  title: 'None Selected',
  body: 'No template selected. Please select a template to view message.',
  repository: PERSONAL,
};

const UNIT_OPTIONS_PLURAL = ['minutes', 'hours', 'days', 'weeks'];
const UNIT_OPTIONS_SINGULAR = ['minute', 'hour', 'day', 'week'];

const classes = BEM.with('WorkflowEventModal');

type FormattedTemplate = {
  bodyFormatted?: React.ReactNode;
  attachmentName?: string;
  attachmentType?: string;
} & Template;

type WorkflowEventModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const transformDynamicVariables = (body: string) => {
  const children = [];
  let lastPos = 0;

  body.replace(detectFormattedVariables, (match, variable, pos) => {
    const pre = body.slice(lastPos, pos);
    children.push(pre);

    const elem = (
      <span className={classes('templateVariable')} key={pos}>
        #{variable}
      </span>
    );
    children.push(elem);

    lastPos = pos + match.length;
    return match;
  });

  const post = body.slice(lastPos);
  children.push(post);

  return children;
};

export default function WorkflowEventModal({ isOpen, onClose }: WorkflowEventModalProps) {
  const deliveryMethodRef = useRef(null);
  const [invalidMessage, setInvalidMessage] = useState('');
  const [template, setTemplate] = useState<FormattedTemplate>(NONE_SELECTED_TEMPLATE);
  const [templateUpdated, setTemplateUpdated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const { accessibilityMode } = useAppSelector(({ ui }) => ({
    accessibilityMode: ui.accessibilityMode,
  }));
  const {
    currentOrganizationId,
    deleteWorkflowEvent,
    getUser,
    isLoading,
    loadTemplate,
    loadWorkflows,
    saveWorkflowEvent,
    selectedWorkflow,
    selectedWorkflowEvent,
    updateSelectedWorkflowEvent,
    workflowEventChanged,
  } = useAMContext();

  const {
    appointmentTimeOffset,
    appointmentTimeOffsetUnit,
    attachmentName,
    attachmentType,
    deliveryMethod,
    id,
    sender,
    templateId,
  } = selectedWorkflowEvent || ({} as WorkflowEvent);
  const initialUnit = appointmentTimeOffsetUnit ?? 'Days';

  const [offsetUnit, setOffsetUnit] = useState(initialUnit);
  const offset = appointmentTimeOffset ? Math.abs(appointmentTimeOffset) : undefined;
  const activeWorkflow = selectedWorkflow?.isActive;
  const validEvent = templateId && !invalidMessage && (deliveryMethod === 'sms' || sender);
  const canSave =
    !isLoading &&
    !activeWorkflow &&
    validEvent &&
    appointmentTimeOffset !== null &&
    workflowEventChanged;
  const direction = appointmentTimeOffset && appointmentTimeOffset >= 0 ? AFTER : BEFORE;
  const isUnsecureDeliveryMethod = deliveryMethod === SMS;
  const [templateAttachmentName, setTemplateAttachmentName] = useState(attachmentName);
  const [templateAttachmentType, setTemplateAttachmentType] = useState(attachmentType);
  const hasAttachment = templateAttachmentName && templateAttachmentType;
  let primaryButtonFragment, attachmentFragment;

  useEffect(() => {
    const findUser = async () => {
      if (!sender) return setUser(null);
      if (getUser) {
        const senderUser = await getUser(sender);
        senderUser && setUser(senderUser);
      }
    };
    if (sender !== user?.id) findUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sender]);

  useEffect(() => {
    const findTemplate = async () => {
      if (!templateId) return setTemplate(NONE_SELECTED_TEMPLATE);
      const aTemplate = (await loadTemplate(templateId)) as FormattedTemplate;

      if (aTemplate.repository === DYNAMIC) {
        aTemplate.bodyFormatted = transformDynamicVariables(aTemplate.body);
      }

      setTemplate(aTemplate);
      setTemplateAttachmentName(aTemplate.attachmentName);
      setTemplateAttachmentType(aTemplate.attachmentType);
    };
    if (templateId !== template?.id) findTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);

  useEffect(() => {
    const lengthOfOffsetUnit = () => {
      if (offsetUnit !== undefined) return offsetUnit.length - 1;
      else return null;
    };
    const length = lengthOfOffsetUnit() ?? 0;
    if (offset && offsetUnit !== undefined && length !== 0) {
      if (offset < 2) {
        if (offsetUnit[length] === 's') setOffsetUnit(offsetUnit.slice(0, -1));
      } else {
        if (offsetUnit[length] !== 's') setOffsetUnit(offsetUnit + 's');
      }
    }
  }, [offset, offsetUnit]);

  useEffect(() => {
    setTemplateUpdated(false);
  }, [id]);

  useEffect(() => {
    setTemplateAttachmentName(undefined);
    setTemplateAttachmentType(undefined);
  }, [isOpen]);

  useEffect(() => {
    setOffsetUnit(selectedWorkflowEvent?.appointmentTimeOffsetUnit ?? 'Days');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkflowEvent?.appointmentTimeOffsetUnit]);

  useEffect(() => {
    if (!selectedWorkflowEvent) return;
    const { appointmentTimeOffset } = selectedWorkflowEvent;
    let errorMessage = '';

    if (appointmentTimeOffset) {
      switch (offsetUnit) {
        case 'minutes' || 'minute':
          if (appointmentTimeOffset < -60 || appointmentTimeOffset > 60) {
            errorMessage = 'Please select a value between 1 and 60 minutes';
          }
          break;
        case 'hours' || 'hour':
          if (appointmentTimeOffset < -24 || appointmentTimeOffset > 24) {
            errorMessage = 'Please select a value between 1 and 24 hours';
          }
          break;
        case 'days' || 'day':
          if (appointmentTimeOffset < -90) {
            errorMessage = 'Please select a value between 1 and 90 days';
          } else if (appointmentTimeOffset > 30) {
            errorMessage = 'Please select a value between 1 and 30 days';
          }
          break;
        case 'weeks' || 'week':
          if (appointmentTimeOffset < -12) {
            errorMessage = 'Please select a value between 1 and 12 weeks';
          } else if (appointmentTimeOffset > 4) {
            errorMessage = 'Please select a value between 1 and 4 weeks';
          }
          break;
        default:
          break;
      }
    }
    setInvalidMessage(errorMessage);
  }, [selectedWorkflowEvent, offsetUnit]);

  const renderAttachment = ({
    templateAttachmentType,
    templateAttachmentName,
  }: {
    templateAttachmentType: string;
    templateAttachmentName: string;
  }) => {
    return (
      <div className={classes('file-details-container')}>
        <AttachmentIcon fileType={templateAttachmentType} className={classes('file-icon')} />
        {templateAttachmentName}
      </div>
    );
  };

  if (selectedWorkflowEvent && hasAttachment) {
    if (templateAttachmentType !== undefined && templateAttachmentName !== undefined) {
      attachmentFragment = (
        <div className={classes('attachment-container')}>
          {renderAttachment({ templateAttachmentName, templateAttachmentType })}
        </div>
      );
    }
  }

  useEffect(() => {
    if (deliveryMethod === SMS && !template?.isSmsCompatible && workflowEventChanged) {
      updateSelectedWorkflowEvent('templateId', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryMethod]);

  const updateOffset = (newDirection: string, newOffset?: number) => {
    if (!newOffset || isNaN(newOffset)) {
      newOffset = undefined;
    }
    updateSelectedWorkflowEvent(
      'appointmentTimeOffset',
      newOffset ? newOffset * (newDirection === BEFORE ? -1 : 1) : null
    );
  };

  async function saveEvent() {
    await saveWorkflowEvent(templateUpdated);
    loadWorkflows();
    onClose();
  }

  async function deleteEvent() {
    await deleteWorkflowEvent();
    loadWorkflows();
    onClose();
  }

  if (activeWorkflow) {
    primaryButtonFragment = (
      <div className={classes('primary-actions')}>
        <button
          aria-label={'Close'}
          className={classes('primary-btn')}
          onClick={onClose}
          type="button"
        >
          CLOSE
        </button>
      </div>
    );
  } else {
    primaryButtonFragment = (
      <div className={classes('primary-actions')}>
        <span className={classes('invalid-message', { hidden: !invalidMessage })}>
          {invalidMessage}
        </span>
        <button
          aria-label={'Cancel'}
          className={classes('primary-btn')}
          onClick={onClose}
          type="button"
        >
          CANCEL
        </button>
        <button
          aria-label={'Save Workflow Rule'}
          className={classes('main-btn', { isLoading })}
          disabled={activeWorkflow ? false : !canSave}
          onClick={() => (activeWorkflow ? onClose() : canSave && saveEvent())}
          type="button"
        >
          {isLoading ? (
            <DotsIndicator color={'#db524b'} size={13} />
          ) : activeWorkflow ? (
            'OK'
          ) : (
            'SAVE'
          )}
        </button>
      </div>
    );
  }

  return (
    <Modal
      bodyClass={classes('body')}
      footerClass={classes('footer')}
      closeClass={classes(`close-button-normal`)}
      hasCloseButton={false}
      isOpen={isOpen}
      size={'large'}
      footerPrimaryActions={primaryButtonFragment}
      footerSecondaryActions={
        id &&
        !activeWorkflow && (
          <button
            aria-label={'Delete'}
            className={classes('delete-btn')}
            onClick={deleteEvent}
            type="button"
          >
            DELETE
          </button>
        )
      }
      className={classes()}
      onRequestClose={onClose}
    >
      <div aria-label={'Manage Workflow Rule'} className={classes('info')}>
        {activeWorkflow && (
          <div className={classes('warning')}>Workflows must be inactive to make any changes</div>
        )}
        <div className={classes('event-form', { activeWorkflow })} aria-disabled={activeWorkflow}>
          <div className={classes('row')}>
            <div className={classNames(classes('sect'), classes('schedule-sect'))}>
              <label>Schedule</label>
              <div className={classes('row')}>
                <input
                  value={offset || ''}
                  type={'number' || undefined}
                  className={classes('input', { offset: true })}
                  onChange={({ target }) =>
                    updateOffset(direction, target.value ? parseInt(target.value) : undefined)
                  }
                  aria-disabled={activeWorkflow}
                  readOnly={activeWorkflow}
                  data-test-id={'workflow-rule-offset-number'}
                />
                <BasicContextMenu
                  options={offset && offset > 1 ? UNIT_OPTIONS_PLURAL : UNIT_OPTIONS_SINGULAR}
                  selected={offsetUnit || ''}
                  className={classes('input', { offsetUnit: true })}
                  hideArrow={true}
                  onChange={(val) => {
                    updateSelectedWorkflowEvent('appointmentTimeOffsetUnit', val);
                    setOffsetUnit(val);
                  }}
                  accessibilityMode={accessibilityMode}
                  ariaDisabled={activeWorkflow}
                  dataTestId={'workflow-rule-offset-unit'}
                />
                <BasicContextMenu
                  options={[BEFORE, AFTER]}
                  selected={direction}
                  className={classes('input', { offsetDirection: true })}
                  hideArrow={true}
                  onChange={(val) => updateOffset(val, offset)}
                  accessibilityMode={accessibilityMode}
                  ariaDisabled={activeWorkflow}
                  dataTestId={'workflow-rule-offset-direction'}
                />
              </div>
            </div>
            <div className={classes('sect')}>
              <label>Delivery</label>
              <ContextMenu
                className={classes('complex-select')}
                event="click"
                offsetY={6}
                position="bottominnerleft"
                relativeTo={deliveryMethodRef.current}
                theme="vertical"
                value={deliveryMethod}
                data-test-id={'template-delivery-input'}
                ariaDisabled={activeWorkflow}
                menu={
                  <WorkflowEventDeliveryMenu
                    handleClick={(newDeliveryMethod) => {
                      updateSelectedWorkflowEvent('deliveryMethod', newDeliveryMethod);
                      if (accessibilityMode && deliveryMethodRef?.current) {
                        (deliveryMethodRef.current as HTMLElement).focus();
                      }
                    }}
                  />
                }
                accessibilityMode={accessibilityMode}
              >
                <div
                  role="button"
                  tabIndex={0}
                  className={classes('message-type-dropdown')}
                  ref={deliveryMethodRef}
                >
                  <div className={classes('message-kind-dropdown')}>
                    <DeliveryMethod
                      copy={`${
                        deliveryMethod === LINK || deliveryMethod === ONE_WAY_LINK
                          ? 'Secure Browser Link: '
                          : 'Unsecure SMS Text: Non-'
                      }HIPAA Compliant`}
                      deliveryMethod={deliveryMethod || LINK}
                      shouldShowCircle
                      shouldShowIcon
                    />
                    <DropDownChevronSvg className={classes('nav-item-dropdown')} aria-hidden />
                  </div>
                </div>
              </ContextMenu>
            </div>
          </div>
          {!isUnsecureDeliveryMethod && (
            <div className={classes('row')}>
              <div className={classes('sect')}>
                <label>Sender</label>
                <RecipientSearchPicker
                  ariaLabelSelect={'Choose Sender'}
                  ariaDisabled={activeWorkflow}
                  autoFocus={false}
                  className={classes('sender')}
                  dataTestId={'workflow-rule-sender'}
                  enabledCapabilities={['patient_network']}
                  excludeRoles
                  excludeSelf={false}
                  excludeTeams
                  onChange={(sender: User) =>
                    sender && updateSelectedWorkflowEvent('sender', sender.id)
                  }
                  organization={{ id: currentOrganizationId }}
                  placeholder={'Choose Sender'}
                  searchTypes={[SearchTypes.USER]}
                  selected={user}
                />
              </div>
            </div>
          )}
          <div className={classes('row')}>
            <div className={classes('sect')}>
              <label>Message</label>
              <div
                className={classes('complex-select', {
                  placeholder: template.id === 'noneSelected',
                })}
              >
                <TemplateContextMenu
                  allowDynamicTemplates
                  disableCustom
                  selectedTemplate={template}
                  setSelectedTemplate={(selectedTemplate: Template) => {
                    setTemplateUpdated(true);
                    updateSelectedWorkflowEvent('templateId', selectedTemplate.id);
                  }}
                  shouldFilterSms={deliveryMethod === SMS}
                  accessibilityMode={accessibilityMode}
                  ariaDisabled={activeWorkflow}
                />
                {template.id !== 'noneSelected' && (
                  <div className={classes('message-body')}>
                    {template.bodyFormatted ?? template.body}
                  </div>
                )}
              </div>
            </div>
          </div>
          {hasAttachment && (
            <div className={classes('row')}>
              <div className={classes('sect')}>{attachmentFragment}</div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
