/* eslint react/no-find-dom-node: 0 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactDOM, {
  unstable_renderSubtreeIntoContainer as renderSubtreeIntoContainer,
  unmountComponentAtNode,
} from 'react-dom';
import _ from 'lodash';
import { noop } from 'node-noop';
import overloadEvent from '../../utils/overloadEvent';
import { detectOnEsc, detectOnSoftClick } from '../../utils';
import propTypes from '../../propTypes';
import BEM from '../../bem';
import PositionedContextMenu from './PositionedContextMenu';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('ContextMenu');
const rootContainerClasses = BEM.with('RootContainer');

const DOC_CLICK_TIMEOUT = 225; // softClick timeout + 25
const PARENT_ACTIVE_CLASS = classes({ active: true }).split(' ')[1];

function isScrollable(el) {
  const style = getComputedStyle(el);
  return (
    el === document.body ||
    ['auto', 'scroll'].includes(style.overflow || style.overflowX || style.overflowY)
  );
}

const _visibleList = [];

export default class ContextMenu extends PureComponent {
  static propTypes = {
    delay: PropTypes.number,
    event: PropTypes.oneOf(['hover', 'click', 'rightClick', 'click+rightClick']),
    hideDelay: PropTypes.number,
    hideOnScroll: PropTypes.bool,
    menu: PropTypes.node.isRequired,
    onHide: PropTypes.func,
    onShow: PropTypes.func,
    relativeTo: propTypes.ref,
    showOnLoad: PropTypes.bool,
    softClick: PropTypes.bool,
    accessibilityMode: PropTypes.bool,
    ariaDisabled: PropTypes.bool,
    isAccessibleActive: PropTypes.bool,
  };

  static defaultProps = {
    delay: 0,
    event: 'hover',
    hideDelay: 0,
    onHide: noop,
    onShow: noop,
    showOnLoad: false,
    softClick: true,
    accessibilityMode: false,
    ariaDisabled: false,
  };

  componentDidMount() {
    const { showOnLoad } = this.props;
    ReactDOM.findDOMNode(this).classList.add(classes());

    if (showOnLoad) {
      window.setTimeout(() => {
        const { relativeTo } = this.props;
        if (relativeTo) this.show(relativeTo);
      }, 200);
    }
  }

  componentDidUpdate(prevProps) {
    const { isAccessibleActive } = this.props;
    if (!this.props.accessibilityMode || this._isVisible) return;
    if (isAccessibleActive !== prevProps.isAccessibleActive) {
      if (isAccessibleActive) {
        window.setTimeout(() => {
          const { relativeTo } = this.props;
          if (relativeTo) this.show(relativeTo);
        }, 200);
      }
    }
  }

  componentWillUnmount() {
    this._clearVisibilityTimeout();
    this.__unlistenDocClick();

    if (this._currentClickEnabledTimer) {
      clearTimeout(this._currentClickEnabledTimer);
      this._disabled = false;
    }

    if (this._isVisible) {
      this.hide();
    } else {
      unmountComponentAtNode(this.getPortalNode());
    }

    ReactDOM.findDOMNode(this).classList.remove(classes());

    if (this.getPortalNode() && this.getPortalNode().parentNode) {
      this.getPortalNode().parentNode.removeChild(this.getPortalNode());
    }
  }

  _getScrolledParent() {
    const parentOf = (node) => node.parentElement || node.parentNode;

    let scrolledParent = parentOf(ReactDOM.findDOMNode(this));
    while (scrolledParent && !isScrollable(scrolledParent))
      scrolledParent = parentOf(scrolledParent);
    return scrolledParent;
  }

  render() {
    const { children, event, softClick, accessibilityMode, ariaDisabled } = this.props;

    let events;
    switch (event) {
      case 'hover':
        events = {
          onMouseOver: this._showWithPotentialDelay,
          onMouseLeave: this._hideWithDelay,
        };
        break;
      case 'click':
        events = {
          onClick: softClick
            ? detectOnSoftClick(this._toggleWithEvent, {
                stopPropagation: true,
              })
            : this._toggleWithEvent,
        };
        break;
      case 'rightClick':
        events = {
          onContextMenu: this._toggleWithEvent,
        };
        break;
      case 'click+rightClick':
        events = {
          onClick: softClick
            ? detectOnSoftClick(this._toggleWithEvent, {
                stopPropagation: true,
              })
            : this._toggleWithEvent,
          onContextMenu: this._toggleWithEvent,
        };
        break;
      default:
    }

    if (accessibilityMode) {
      events = {
        ...events,
        onKeyDown: (e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            if (ariaDisabled) {
              e.stopPropagation();
              e.preventDefault();
            } else {
              this._toggleWithEvent(e);
            }
          }
        },
      };
    }

    const onlyChild = React.Children.only(children);
    events = _.reduce(
      events,
      (all, fn, eventName) => _.set(all, eventName, overloadEvent(onlyChild, eventName, fn)),
      events
    );

    return React.cloneElement(onlyChild, events);
  }

  _toggleWithEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this._isVisible) {
      this.hide();
    } else {
      this.show(this._getRelativeTo(e));
    }
  };

  _getRelativeTo = (e) => {
    return this.props.relativeTo || e.currentTarget || e.target;
  };

  _showWithPotentialDelay = (e) => {
    this._clearVisibilityTimeout();
    if (this._isVisible) return;

    const relativeTo = this._getRelativeTo(e);

    if (this.props.delay) {
      this._showTimeout = setTimeout(() => this.show(relativeTo), this.props.delay);
    } else {
      window.requestAnimationFrame(() => this.show(relativeTo));
    }
  };

  _clearVisibilityTimeout = () => {
    if (this._showTimeout) {
      clearTimeout(this._showTimeout);
      this._showTimeout = null;
    }
    if (this._hideTimeout) {
      clearTimeout(this._hideTimeout);
      this._hideTimeout = null;
    }
  };

  getPortalNode() {
    if (!this._portalNode) {
      this._portalNode = document.createElement('div');
      this._portalNode.className = rootContainerClasses();
      document.body.appendChild(this._portalNode);
    }
    return this._portalNode;
  }

  renderMenuInPortal() {
    const { menu, ...otherProps } = this.props;
    const container = (
      <PositionedContextMenu {...otherProps} ref={this._setMenu} onClick={this.hide}>
        {menu}
      </PositionedContextMenu>
    );
    renderSubtreeIntoContainer(this, container, this.getPortalNode());
  }

  _setMenu = (ref) => {
    this.menu = ref;
  };

  show = (relativeTo) => {
    if (this._disabled) return;
    if (this._isVisible && this._relativeTo === relativeTo) return;

    this.renderMenuInPortal();

    this._relativeTo = relativeTo;
    this._isVisible = true;
    this.__listenDocClick();

    if (this.props.event === 'hover') {
      window.requestAnimationFrame(() => {
        const menuEl = ReactDOM.findDOMNode(this.menu);
        menuEl.addEventListener('mouseenter', this._clearVisibilityTimeout);
        menuEl.addEventListener('mouseleave', this._hideWithDelay);
      });
    }
    this._getScrolledParent().addEventListener('scroll', this.__parentScrolled, false);

    _visibleList.forEach((other) => other.hide());
    _visibleList.push(this);

    this.menu?.show(relativeTo);
    const node = ReactDOM.findDOMNode(this);
    node.classList.add(PARENT_ACTIVE_CLASS);
    this.props.onShow();
  };

  hide = () => {
    if (!this._isVisible) return;
    _.pull(_visibleList, this);

    this.__unlistenDocClick();
    this._clearVisibilityTimeout();
    this._getScrolledParent().removeEventListener('scroll', this.__parentScrolled, false);

    if (this.props.event === 'hover') {
      const menuEl = ReactDOM.findDOMNode(this.menu);
      menuEl.removeEventListener('mouseenter', this._clearVisibilityTimeout);
      menuEl.removeEventListener('mouseleave', this._hideWithDelay);
    }

    this.menu?.hide();
    unmountComponentAtNode(this.getPortalNode());
    ReactDOM.findDOMNode(this).classList.remove(PARENT_ACTIVE_CLASS);
    this.props.onHide();

    if (this.props.accessibilityMode) {
      if (
        this._relativeTo.getAttribute('tabindex') === '0' ||
        this._relativeTo.getAttribute('role') === 'button' ||
        this._relativeTo.tagName === 'BUTTON'
      ) {
        this._relativeTo.focus();
      } else {
        let focusableChildren = this._relativeTo.querySelectorAll('[role="button"]');
        if (focusableChildren.length === 0) {
          focusableChildren = this._relativeTo.querySelectorAll('button');
        }
        const firstChild = focusableChildren.length > 0 ? focusableChildren[0] : null;
        if (firstChild) firstChild.focus();
      }
    }

    this._relativeTo = null;
    this._isVisible = false;
  };

  _hideWithDelay = () => {
    this._clearVisibilityTimeout();
    if (this.props.hideDelay) {
      this._hideTimeout = setTimeout(this.hide, this.props.hideDelay);
    } else {
      window.requestAnimationFrame(this.hide);
    }
  };

  enable() {
    this._disabled = false;
    if (this._shouldBecomeVisibleOnEnable) this.show(this._shouldBecomeVisibleOnEnableRelativeTo);
  }

  disable() {
    if (this._isVisible) {
      this._shouldBecomeVisibleOnEnable = true;
      this._shouldBecomeVisibleOnEnableRelativeTo = this._relativeTo;
    }
    this._disabled = true;
    this.hide();
  }

  __parentScrolled = () => {
    if (this.props.hideOnScroll) {
      this.hide();
    } else {
      this.menu && this.menu.reposition();
    }
  };

  __docClick = () => {
    this._disabled = true;
    this._currentDocClickTimer = window.requestAnimationFrame(this.hide);
    this._currentClickEnabledTimer = setTimeout(() => {
      this._disabled = false;
    }, DOC_CLICK_TIMEOUT);
  };

  __escPressed = detectOnEsc(() => {
    if (this._isVisible) {
      this.hide();
    }
  });

  __onKeyPressed = (e) => {
    if (e.key === KEYMAP.ESCAPE) {
      this.__escPressed(e);
    }
    if (e.key === KEYMAP.TAB) {
      setTimeout(() => {
        const activeEl = document.activeElement;
        const menuEl = ReactDOM.findDOMNode(this.menu);
        if (!menuEl.contains(activeEl) && this._isVisible) {
          this.hide();
        }
      }, 0);
    }
  };

  __listenDocClick() {
    // inner clicks call stopPropagation so it'll reach the doc only if wasn't stopped
    document.addEventListener('click', this.__docClick, false);
    document.addEventListener('keydown', this.__onKeyPressed, false);
  }

  __unlistenDocClick() {
    if (this._currentDocClickTimer) clearTimeout(this._currentDocClickTimer);
    document.removeEventListener('click', this.__docClick, false);
    document.removeEventListener('keydown', this.__onKeyPressed, false);
  }
}
