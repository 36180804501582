import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import styles from './Persona.module.css';
import { CtaButton } from 'admin/shared-components/CtaButton/CtaButton';
import { Table } from 'admin/shared-components/Table/Table';
import { ActionBar } from 'admin/shared-components/ActionBar/ActionBar';
import { PersonaSearchResponse, PersonaRowData } from 'admin/types';
import { mobxInjectSelect } from 'common/utils';
import { useAppSelector } from 'redux-stores';

type PersonaTableProps = {
  showPersonaForm: boolean;
  isSuccessToastOpen: boolean;
  setShowPersonaForm: (shouldShow: boolean) => void;
  setEditMode: (isEdit: boolean) => void;
  setIsSuccessToastOpen: (isSuccessToastOpen: boolean) => void;
  setToastMessage: (message: string) => void;
};

type MobxProps = {
  findPersonas: (params: {
    query: string;
    page: number;
    selectedOrganizationId: string;
  }) => Promise<PersonaSearchResponse>;
};
type PersonaTableRow = {
  name: string;
  titles: string;
  description: string;
  parentPersonaId: string;
  id: string;
};

function PersonaTable({
  findPersonas,
  setShowPersonaForm,
  setIsSuccessToastOpen,
  setToastMessage,
  setEditMode,
}: PersonaTableProps & MobxProps) {
  const [rows, setRows] = useState<
    {
      name: string;
      titles: string;
      description: string;
      parentPersonaId: string;
      id: string;
    }[]
  >([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const updateSearchInputValue = (text: string) => {
    setSearchInputValue(text);
  };
  const [isPaginated, setIsPaginated] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [queryValue, setQueryValue] = useState('');

  const selectedOrganizationId = useAppSelector((state) => state.admin.selectedOrganizationId);

  const columns = [
    { field: 'name', headerName: 'Permission Group' },
    { field: 'titles', headerName: 'Title(s)' },
    { field: 'description', headerName: 'Description' },
    { field: 'parentPersonaId', headerName: 'Parent' },
  ];

  const setRowData = useCallback(
    ({ personaList, rows }: { personaList: PersonaSearchResponse; rows: PersonaTableRow[] }) => {
      const rowsSnapshot = [...rows] as PersonaTableRow[];
      if (personaList.pageNumber < personaList.totalPages) {
        setIsPaginated(true);
        setCurrentPageNumber(personaList.pageNumber + 1);
      } else {
        setIsPaginated(false);
      }
      personaList.data.forEach(
        ({ personaId, name, description, parentPersonaId, titles }: PersonaRowData) => {
          rowsSnapshot.push({
            id: personaId,
            name,
            description: description || 'N/A',
            parentPersonaId: parentPersonaId || 'N/A',
            titles: titles ? titles.join(', ') : 'N/A',
          });
        }
      );
      setRows(rowsSnapshot);
    },
    [setCurrentPageNumber, setRows]
  );

  const getTableData = useCallback(
    async ({
      rows,
      page = 1,
      event,
      currentQueryValue = '',
    }: {
      rows: PersonaTableRow[];
      page?: number;
      event?: React.ChangeEvent<HTMLInputElement>;
      currentQueryValue?: string;
    }) => {
      if (!currentQueryValue) {
        const query = event?.target?.value;
        setQueryValue(query ?? '');
      } else {
        setQueryValue(currentQueryValue);
      }
      const response = await findPersonas({
        page,
        query: currentQueryValue,
        selectedOrganizationId,
      });
      setRowData({ personaList: response, rows });
    },
    [findPersonas, setRowData, selectedOrganizationId]
  );

  const openForm = (row: PersonaTableRow, editMode = true) => {
    setEditMode(editMode);
    setShowPersonaForm(true);
  };

  const onCreate = () => {
    setEditMode(true);
    setShowPersonaForm(true);
  };

  const onRowDelete = () => {
    setToastMessage('Permission Group Deleted');
    setIsSuccessToastOpen(true);
  };

  useEffect(() => {
    getTableData({ rows: [] });
  }, [getTableData]);

  const actions = [
    { name: 'Edit', type: 'edit' },
    { name: 'Delete', type: 'delete' },
  ];

  return (
    <div className={classNames(styles.personaTable)}>
      <ActionBar
        hasSearch={false} // TODO - add search functionality
        onInputChange={(e) => updateSearchInputValue(e.target.value)}
        searchValue={searchInputValue}
        dataTestId={'personaSearch'}
        handleClearClick={() => {
          getTableData({ rows: [] });
          setSearchInputValue('');
        }}
      />
      <div className={classNames(styles.createButton)}>
        <CtaButton primary label={'Create New Permission Group'} onClick={() => onCreate()} />
      </div>
      <Table
        actionsOrder={actions}
        columns={columns}
        onRowClick={(row) => {
          openForm(row, false);
        }}
        onRowEdit={(row) => {
          openForm(row);
        }}
        onRowDelete={onRowDelete}
        rows={rows}
        onPaginate={() =>
          getTableData({ page: currentPageNumber, rows, currentQueryValue: queryValue })
        }
        paginated={isPaginated}
      />
    </div>
  );
}

export default mobxInjectSelect<PersonaTableProps, MobxProps>({
  personaStore: ['findPersonas'],
})(PersonaTable);
