// @ts-nocheck
import _omitBy from 'lodash-bound/omitBy';
import btoa from 'btoa';
import Hashes from 'jshashes';
import { isEmail } from '../utils/email';
import { isPhone, normalizePhoneUS } from '../utils/phone';
import Camelizer from '../utils/Camelizer';
import BaseAPI, { recoverFromNotFound, ROUTES, VERSIONS } from './BaseAPI';

const { VERSION_TWO } = VERSIONS;
const { USER_FIND, USER_FIND_ME } = ROUTES;

const md5 = new Hashes.MD5();

export default class UsersAPI extends BaseAPI {
  static getSignInAuthHeader(productKey: string, userId: string, password: string) {
    const authString = [productKey, userId, password].join(':');
    const authHash = btoa(authString);
    const authHeader = `XMPP-Simple ${authHash}`;

    return authHeader;
  }

  getAnonymousAuthHeader(udid: string) {
    const key = this.config.signUpHashKey;
    const salt = this.config.signUpHashSalt;
    const verifier = [key, salt, udid].join(':');
    const verifierHash = md5.hex(verifier);

    const trueVerifier = [
      verifierHash.substr(0, 2),
      verifierHash.substr(verifierHash.length - 2),
    ].join('');

    const authString = [this.config.productKey, key, salt, udid, trueVerifier].join(':');
    const authHash = btoa(authString);

    return `XMPP-Anonymous ${authHash}`;
  }

  getSignInAuthHeader(...args) {
    return this.constructor.getSignInAuthHeader(...args);
  }

  async checkLogin(userId, params) {
    const headers = {
      Authorization: this.getAnonymousAuthHeader(params.udid),
    };

    const query = {
      device: params.udid,
      tt_product_version: this.config.partnerName,
    };

    if (isPhone(userId)) {
      query.phones = normalizePhoneUS(userId);
    } else {
      query.emails = userId;
    }

    const res = await this.httpClient.get('/v4/login_check', {
      headers,
      query,
    });

    if (res?.data?.[0].baseUrl && res?.data?.[0].baseUrl !== this.config.baseUrl) {
      this.host.configure({ baseUrl: res.data[0].baseUrl });
    }

    return res;
  }

  async signUp(params) {
    const headers = {
      Authorization: this.getAnonymousAuthHeader(params.udid),
    };

    const data = {
      primary_email: params.email,
      unique_id: params.email,
      password: params.password,
      push_routing: this.config.pushAgent,
      product: this.config.productKey,
      country_code: params.countryCode,
      device_id: params.udid,
      version: this.config.version,
    };

    const res = await this.httpClient.post('/v2/signup', {
      headers,
      data,
    });

    return res;
  }

  async signIn(headers, params) {
    const data = {
      device_id: params.udid,
      metadata: {
        device_type: 'js_sdk',
        device_version: __SDK_VERSION__,
        tt_product_version: this.config.partnerName,
        user_agent: global.navigator && global.navigator.userAgent,
      },
      push_routing: this.config.pushAgent,
      version: this.config.version,
      want_condensed_replays: this.config.wantCondensedReplays,
      want_processing_events: this.config.wantCondensedReplays,
      want_lazy_load: this.config.lazyLoading,
    };

    const url = '/v5/login';

    if (this.config.isFHIRLaunchSession) {
      headers['tt-x-ehr-login-from'] = 'epic';
      if (params.jwt) {
        data.ehr_login_jwt = params.jwt;
      }
    }

    const res = await this.httpClient.post(url, {
      headers,
      data,
    });

    return res;
  }

  async signOut() {
    const res = await this.httpClient.post('/v2/logout');
    return res;
  }

  isMultiUserAccountError(error: Record<string, string>) {
    return error.status === 400 && error.text.includes('connected to multiple accounts');
  }

  async lookup(id: string) {
    const { version = VERSION_TWO } = super.getVersion(USER_FIND);
    let res;

    const data = {
      display_name: id,
    };

    if (isEmail(id)) {
      data['email_address'] = id;
    } else if (isPhone(id)) {
      data['phone_number'] = id;
    }

    try {
      res = await this.httpClient.post('/:version/user_lookup/:id', {
        urlParams: { id, version },
        data,
      });
    } catch (e) {
      if (e.status === 400 && !this.isMultiUserAccountError(e)) {
        return null;
      } else {
        throw e;
      }
    }

    return res.data;
  }

  async wipe(id: string) {
    const res = await this.httpClient.post('/v2/wipe', {
      data: {}, // Server assumes that all JSON POSTs with Content-Type will have a payload
      query: { token: id },
    });

    return res;
  }

  @recoverFromNotFound()
  async find(
    id: string,
    /*: ?{ organizationId?: ?string }*/
    { organizationId } = {}
  ) {
    const { version = VERSION_TWO } = super.getVersion(USER_FIND);

    if (isEmail(id)) {
      id = id.toLowerCase();
    }

    const headers = organizationId ? { 'TT-X-Organization-Key': organizationId } : null;
    const res = await this.httpClient.get('/:version/user/:id', {
      headers,
      urlParams: { id, version },
      query: { render_metadata: true },
    });
    return res.data;
  }

  async findMe({ organizationId } = {}) {
    const { version = VERSION_TWO } = super.getVersion(USER_FIND_ME);
    const headers = organizationId ? { 'TT-X-Organization-Key': organizationId } : null;

    const res = await this.httpClient.get('/:version/user', {
      headers,
      query: { render_metadata: true },
      urlParams: { version },
    });

    return res.data;
  }

  async update(
    id: string,
    {
      avatarFile = undefined,
      displayName = undefined,
      dnd = undefined,
      dndText = undefined,
      dndExpireAt = undefined,
      firstName = undefined,
      incomingCallNotification = undefined,
      incomingCallSound = undefined,
      lastName = undefined,
      removeAvatar = undefined,
      status = undefined,
    }
  ) {
    const files = {};
    if (removeAvatar) files['avatar'] = null;
    else if (avatarFile) files['avatar'] = avatarFile;

    const data = _omitBy.call(
      {
        display_name: displayName,
        dnd_expire_at: dndExpireAt,
        dnd_text: dndText,
        dnd: dnd,
        first_name: firstName,
        incoming_call_notification: incomingCallNotification,
        incoming_call_sound: incomingCallSound,
        last_name: lastName,
        status: status,
      },
      (v, k) => typeof v === 'undefined'
    );

    await this.httpClient.post('/v2/user/:id/settings', {
      urlParams: { id },
      data,
      files,
    });

    return {
      id,
      ...(avatarFile && avatarFile.preview ? { avatarUrl: avatarFile.preview } : null),
    };
  }

  async updatePassword(
    id: string,
    currentPassword: string,
    newPassword: string,
    productKey: string
  ) {
    const headers = {
      Authorization: this.getSignInAuthHeader(productKey, id, currentPassword),
    };

    const res = await this.httpClient.post('/v2/user/:id/password', {
      urlParams: { id },
      headers,
      data: {
        password: newPassword,
      },
    });

    return res;
  }

  async setAutoForward(
    userId: string,
    organizationId: string,
    {
      dnd,
      receiverId,
      entitiesIds,
    }: {
      dnd: boolean;
      receiverId: string;
      entitiesIds?: string[];
    }
  ) {
    const res = await this.httpClient.post('/v2/user/:userId/organization/:organizationId', {
      urlParams: { userId, organizationId },
      data: {
        dnd,
        ...(receiverId && { dnd_auto_forward_receiver: receiverId }),
        ...(entitiesIds && { dnd_auto_forward_entities: entitiesIds }),
      },
    });

    return res;
  }

  async removeAutoForward(userId: string, organizationId: string) {
    const res = await this.httpClient.del(
      '/v2/user/:userId/organization/:organizationId/dnd_auto_forward_receiver',
      { urlParams: { userId, organizationId } }
    );
    return res;
  }

  async removeExtendedAutoForwardOptions(userId: string, organizationId: string) {
    const res = await this.httpClient.del(
      '/v2/user/:userId/organization/:organizationId/dnd_auto_forward_entities',
      { urlParams: { userId, organizationId } }
    );
    return res;
  }

  async setAwayMessage(
    userId: string,
    organizationId: string,
    {
      dnd,
      dndText,
    }: {
      dnd: boolean;
      dndText?: string | null | undefined;
    }
  ) {
    await this.httpClient.post('/v5/user/:userId/organization/:organizationId', {
      urlParams: { userId, organizationId },
      data: {
        network: {
          patient: {
            dnd,
            dnd_text: dndText,
          },
        },
      },
    });
  }

  async setEulaAccepted(userId: string, organizationId: string, accept: boolean) {
    const res = await this.httpClient.post('/v2/user/:userId/organization/:organizationId', {
      urlParams: { userId, organizationId },
      data: {
        eula: !accept, // True for displaying the prompt and false to never display it again
      },
    });

    return res;
  }

  @recoverFromNotFound()
  async getPreferences(userId) {
    const res = await this.httpClient.get('/v2/user/:userId/preferences', {
      urlParams: { userId },
    });
    return res.data;
  }

  @recoverFromNotFound()
  async editPreferences({ organizationId, quickReplies, dndTextTemplates, userId }) {
    const res = await this.httpClient.post('/v2/user/:userId/preferences', {
      headers: { 'TT-X-Organization-Key': organizationId },
      data: Camelizer.underscoreObject({ quickReplies, dndTextTemplates }),
      urlParams: { userId },
    });
    return res.data;
  }
}
